<template>
<div class='content'>
  <div class="nav_top">
    <HeaderBase :sear="'hide'" :allNo="'hide'"/>
    <div id="logo">
      <img @click="this.$router.push('/')" src="../../assets/7w.png" alt="">
    </div>
    <ul class="select_list">
        <li class="list_li_cho" :class="chooseon==index?'chooseon':''"
          v-for="(item,index) in optionsNational"
          :key="index"
          @click="choseNational(item,index)"
          >
            <el-image style="width: 120px; height: 70px" :src="item.flag" />
            <span class="name">{{item.countryName}}</span>
          </li>
      </ul>
  </div>
  <div class="content_body">
    <div class="select_con">
      <h2 class="zhuanqu">{{chooseonName+'咖啡专区'}}</h2>
      <div class="main_select"> <!-- 导航栏 -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
          v-for="(i,k) in panList" :key="k"
          :label="i.label"
          :name="i.name"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="main_msg" v-if="false">
        <div class="msg_left">
          <div id="card_body">
            <div id="table_head">
              <tr v-for="(i,index) in tbtitle" :key="index">
                <th>{{i}}</th>
              </tr>
            </div>
            <vue-seamless-scroll :data="sctable"
                :class-option="optionHover"
                class="seamless-warp"
                v-loading="loading">
              <div id="table_body">
                <table v-if="sctable.length!=0">
                  <tr
                  v-for="(k,index1) in sctable" :key="index1">
                    <!-- <el-tooltip :content="k.productName"
                    placement="left" effect="light"> -->
                    <td v-text="k.productName"></td>
                    <!-- </el-tooltip> -->
                    <td style="color:#f04854;" v-text="k.price"></td>
                    <td v-text="k.amount"></td>
                    <td v-text="k.siteName"></td>
                    <td v-text="k.ts"></td>
                  </tr>
                </table>
                <p class="nodata" v-else>{{$t('m.index.No_transaction_dynamics')}}</p>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="msg_right">
          <div class="right_title">
            <span class="font_span">{{$t('m.special.Standard_price_action')}}</span>
            <el-select v-model="titleValue" :placeholder="$t('m.special.Please_select_a_variety')" @change="ttchange"
            size="medium">
              <el-option
                v-for="item in titleOptions"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="right_echats">
            <div id="show"></div>
          </div>
        </div>
      </div>
      <div class="main_msg" v-if="merchantMsg">
        <el-descriptions title="专区信息" size="large">
          <!-- <el-descriptions-item >
            <el-image style="width: 120px; height: 70px" :src="merchantMsg.img" fit="cover" />
          </el-descriptions-item> -->
          <!-- <el-descriptions-item :label="'供应商名称'">{{merchantMsg.nickName}}</el-descriptions-item> -->
          <!-- <el-descriptions-item :label="'供应商邮箱'">{{merchantMsg.email}}</el-descriptions-item> -->
          <el-descriptions-item :label="'点击切换展示全部'">
            <div class="cont_img" v-html="merchantMsg.content"
              :style="showAll?'max-height: none;':''"
              @click="showAll=!showAll"></div>
          </el-descriptions-item
          >
        </el-descriptions>
      </div>
      <div class="main_detail">
        <div class="detail_title">
          {{'专区咖啡生豆'}}
        </div>
        <div class="detail_body">
          <!-- 筛选逻辑 -->
          <div class="shaixuan">
            <span class="xuan">{{$t('m.personalcenter_myorder.tacitly_approve')}}</span>
            <ul class="selectul">
              <!-- <li class="selectli">
                  <el-select v-model="salesvalue"
                  placeholder="销量"
                  @change="filtrateInquire('sale',salesvalue)"
                  size="small">
                  <el-option
                    v-for="item in optionsxiaoliang"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </li> -->
              <!-- <li class="selectli">
                  <el-select v-model="pricevalue"
                  placeholder="价格"
                  @change="filtrateInquire('price',pricevalue)"
                  size="small">
                  <el-option
                    v-for="item in optionsPrice"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </li> -->
            </ul>
            <div class="spanlist">
              <el-tag v-for="(item, index) in tagsList" :key="index"
                closable
                size="small"
                @close="handleClose(item, index)">
                {{ item.name }}
              </el-tag>
            </div>
            <div class="selectname">
              <el-input
                size="small"
                v-model="inputName"
                placeholder="请输入产品名称"
                class=""
              >
                <template #append>
                  <el-button :icon="Search" @click="clickSelectname" />
                </template>
              </el-input>
            </div>
            <!-- <span class="xuan">筛选</span> -->
          </div>
          <div class="title_div" v-if="zhuangyuansName"> <!-- 庄园品级 -->
            <span class="navspan">{{zhuangyuansName}}</span>
            <ul class="huotime">
              <li v-for="i in zhuangyuans" :key="i.id"
              @click="handleAddMulti('zhuangyuans',i.classificationName)">
                {{i.classificationName}}
              </li>
            </ul>
          </div>
          <div class="title_div" v-if="pinjisName"> <!-- 生豆品级 -->
            <span class="navspan">{{pinjisName}}</span>
            <ul class="huotime">
              <li v-for="i in pinjis" :key="i.id"
              @click="handleAddMulti('pinjis',i.classificationName)">
                {{i.classificationName}}
              </li>
            </ul>
          </div>
          <div class="title_div" v-if="chanqusName"> <!-- 产区名称 -->
            <span class="navspan">{{chanqusName}}</span>
            <ul class="huotime">
              <li v-for="i in chanqus" :key="i.id"
              @click="handleAddMulti('chanqus',i.classificationName)">
                {{i.classificationName}}
              </li>
              <!-- <li class="more">
                <span class="morespan">更多</span>
              </li> -->
            </ul>
            <div class="more">
              <span class="morespan" @click="selectAllChanqu">其他</span>
            </div>
          </div>
          <div class="title_div" v-if="chulisName"> <!-- 处理法 -->
            <span class="navspan">{{chulisName}}</span>
            <ul class="huotime">
              <li v-for="i in chulis" :key="i.id"
              @click="handleAddMulti('chulis',i.classificationName)">
                {{i.classificationName}}
              </li>
            </ul>
          </div>
          <div class="cont_body" v-loading="yushouLoading">
            <ul class="list">
              <li class="list_li" v-for="(item,index) in yushouList" :key="index">
                <img :src="item.pic" alt="">
                <span class="minispan">{{
                    item.previewStatus==0?'现货':
                    item.previewStatus==1?'预售':
                    item.previewStatus==3?'易货':''}}</span>
                <div class="right_cc">
                  <div class="right_cc_left">
                    <p class="name">{{$t('m.index.product_name')}}：<strong>{{item.name}}</strong></p>
                    <p class="price_stock">
                      <span class="price">{{'￥'}}<NologindataVue :price='item.price' /></span>
                      <span class="stock">{{$t('m.reservation_varietiy_details.guigeStock')}}：{{item.stock + item.unit}}</span>
                    </p>
                    <p class="area_time">
                      <span class="area">{{item.previewStatus==3 ? $t('m.barter_detail.place_of_delivery')+item.deliveryPlace :`${$t('m.merchantworkbench.producer')}：`+item.originPlace}}</span>
                      <span class="time">{{$t('m.reservation_varietiy_details.Validity_of_Quotation')}}{{item.offerExpiryDate}}</span>
                    </p>
                  </div>
                  <div class="right_cc_right">
                    <div class="btnspan" @click="toDetails(item)"><span>详情</span><el-icon><ArrowRightBold /></el-icon></div>
                  </div>
                </div>
              </li>
              <div class="nodata" v-if="yushouList.length == 0">
                <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
              </div>
            </ul>
          </div>
        </div>
        <div class="fenye">
          <div class="showfooter" v-if="totalnum<pagesize?false:true">
            <el-pagination
              v-model:currentPage="currentPage"
              :page-size="pagesize"
              layout="prev, pager, next, jumper"
              :total="totalnum"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divfooter">
    <Footer/>
  </div>
</div>
</template>

<script>
import HeaderBase from '../../components/HeaderBase.vue'
import NologindataVue from '../../components/Nologindata.vue'
import * as echarts from 'echarts'

import Footer from '../index/Footer.vue'
import http from '../../api/http'
import { reactive, ref } from 'vue'
import moment from 'moment'
import i18n from '@/tools/i18n.js'
import { ElMessage } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
const { t } = i18n.global

// 查国家馆专区
const cmscscountries = (data) => {
  return http.get('/mall-portal/home/queryTheListInformationOfNationalPavilions', data)
}
// 查询专区分类一级分类
const queryZoneClassificationLevel1Classification = (data) => {
  return http.get(`/mall-portal/zoneClassification/queryZoneClassificationLevel1Classification/${data}`)
}
// 查询专区二级分类
const queryChildNodesBasedOnTheZoneSParentID = (data) => {
  return http.get(`/mall-portal/zoneClassification/queryChildNodesBasedOnTheZoneSParentID/${data}`)
}
// 获取成交明细
const getSuccessOrderList = (data) => {
  return http.get('/mall-portal/home/getSuccessOrderList', data)
}
// 查用户专区商品 & 筛选查询
const search = (data) => {
  return http.get('/mall-portal/product/search', data)
}
// 品种(专题)数据
const topicList = (data) => {
  return http.get('/mall-portal/home/topicList')
}
// 走势图
const queryTheStandardPriceTrendAccordingToTheVarietyAndMerchantId = (data) => {
  return http.get('/mall-portal/home/queryTheStandardPriceTrendAccordingToTheVarietyAndMerchantId', data)
}
// // 根据商家id查询商家信息
// const queryMerchantLiveBroadcastInformationBasedOnMerchantId = (data) => {
//   return http.get('/mall-portal/aliyunLive/queryMerchantLiveBroadcastInformationBasedOnMerchantId', data)
// }
// 根据国家馆code查专区信息
const cmsCsCountryIntro = (data) => {
  return http.get('/mall-merchant/mall/cmsCsCountryIntro/list', data)
}
const tbtitle = [
  t('m.index.product_name'),
  t('m.index.transaction_price'),
  t('m.index.turnover'),
  t('m.index.region'),
  t('m.index.Transaction_time')
]
export default {
  name: 'region',
  data () {
    return {
      optionsNational: [],
      chooseon: 0,
      chooseonName: '',
      activeName: 'special',
      panList: [
        { label: this.$t('m.special.Zone_homepage'), name: 'special' },
        // { label: this.$t('m.search.Capacity_presale'), name: 'prsell' },
        { label: this.$t('m.search.Spot_listing'), name: 'cash' }
        // { label: this.$t('m.special.Resale_hall'), name: 'reseller' },
        // { label: this.$t('m.special.Auction_hall'), name: 'Auction' }
        // { label: this.$t('m.special.Barter_hall'), name: 'barter' }
      ], // 订单状态标题内容
      sctable: ref([]),
      loading: ref(true),
      titleOptions: ref([]),
      titleValue: ref(''), // 走势选择框选中的值

      showAll: ref(false),
      merchantMsg: ref(''), // 供应商信息

      // 筛选
      salesvalue: ref(''),
      pricevalue: ref(''),
      optionsPrice: ref([{ value: '降序', label: '价格降序' }, { value: '升序', label: '价格升序' }]),
      optionsxiaoliang: ref([{ value: '降序', label: '销量降序' }, { value: '升序', label: '销量升序' }]),
      zhuangyuansName: '',
      zhuangyuans: [], // 庄园筛选
      pinjisName: '',
      pinjis: [], // 品级筛选
      chanqusName: '',
      chanqus: [], // 产区筛选
      chulisName: '',
      chulis: [], // 处理法筛选
      paramsList: {
        sort: null, // 排序字段:0->按相关度；1->销量从低到高；2->销量从高到低；3->价格从低到高；4->价格从高到低
        pageSize: 20,
        pageNum: 1,
        previewStatus: 0,
        merchantId: null,
        manorName: null, // 庄园名称
        originPlace: null, // 产地 & 产区名称
        grade: null, // 生豆品级
        processWay: null, // 处理法
        keyword: null // 关键字 & 搜索商品名称
      },
      manorNameList: [], // 选中庄园名称
      originPlaceList: [], // 选中产地 & 产区名称
      gradeList: [], // 选中生豆品级
      processWayList: [], // 选中处理法
      tagsList: reactive([]), // 选中的筛选条件
      inputName: '', // 模糊搜索输入框的值
      yushouList: [], // 展示数据
      yushouLoading: ref(false), // 数据loading
      // 分页
      // 存放总数据个数
      totalnum: ref(0),
      // 存放一页显示个数
      pagesize: ref(20),
      // 默认展示页
      currentPage: ref(1),
      chartsSetOption: { // echarts图示数据
        // title: {
        //   text: '行情展示'
        // },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value',
          // max: 'dataMax',
          width: 200,
          nameTextStyle: {
            overflow: 'breakAll'
          },
          splitNumber: 7
        },
        series: [
          {
            // smooth: true,
            type: 'line',
            stack: 'Total',
            symbol: 'circle', // 改为实心点
            data: [],
            itemStyle: {
              color: '#c5854d', // 折线点的颜色
              lineStyle: {
                color: '#d3bea5'// 折线的颜色
              }
            }
          }
        ]
      }
    }
  },
  components: { HeaderBase, Footer, NologindataVue },
  computed: {
    optionHover () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 7, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  watch: {
    tagsList: {
      handler (newV, oldV) { // 监听选中的筛选条件
        // console.log(newV)
        this.paramsList.manorName = this.manorNameList.join() // 庄园名称
        this.paramsList.originPlace = this.originPlaceList.join() // 产区名称
        this.paramsList.grade = this.gradeList.join() // 生豆品级
        this.paramsList.processWay = this.processWayList.join() // 处理法
        // console.log(oldV)
        // console.log(this.paramsList)
        this.initMerId(this.paramsList)
      },
      deep: true
    }
  },
  methods: {
    async initLevelOneData (id) { // 初始化专区一级筛选
      // const data = id
      const res = await queryZoneClassificationLevel1Classification(id)
      console.log(res)
      if (res.data.code === 200) {
        this.zhuangyuansName = ''
        this.zhuangyuans = []
        this.pinjisName = ''
        this.pinjis = []
        this.chanqusName = ''
        this.chanqus = []
        this.chulisName = ''
        this.chulis = []
        if (res.data.data.length === 0) {
          return
        }
        for (const element of res.data.data) {
          // console.log(element)
          switch (element.classificationName) {
            case '庄园名称':
              this.zhuangyuansName = element.classificationName
              this.zhuangyuans = await this.initLevelTwoData(element.id)
              break
            case '生豆品级':
              this.pinjisName = element.classificationName
              this.pinjis = await this.initLevelTwoData(element.id)

              break
            case '产区名称':
              this.chanqusName = element.classificationName
              this.chanqus = await this.initLevelTwoData(element.id)
              break
            case '处理法':
              this.chulisName = element.classificationName
              this.chulis = await this.initLevelTwoData(element.id)
              break
            default:
              break
          }
        }
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    async initLevelTwoData (id) { // 初始化专区二级筛选
      const res = await queryChildNodesBasedOnTheZoneSParentID(id)
      // console.log(res)
      if (res.data.code === 200) {
        return res.data.data
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
      // return res
    },
    async orderListdata (memberId) { // 初始化交易动态信息
      const data = {
        merchantId: memberId
      }
      const getList = await getSuccessOrderList(data)
      this.sctable = getList.data.data
      this.loading = false
      // console.log(this.sctable)
    },
    async initData () { // 初始化国家馆
      const data = {
        pageNum: 1,
        pageSize: 20
      }
      const res = await cmscscountries(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.optionsNational = res.data.data.records
        if (res.data.data.records.length !== 0) {
          this.choseNational(this.optionsNational[0], 0)
        }
      }
    },
    handleClick (tab, event) {
      const PageId = document.querySelector('.detail_title')
      switch (tab.props.name) {
        case 'special':
          this.currentPage = 1
          this.pricevalue = ''
          this.salesvalue = ''
          this.paramsList.previewStatus = 0
          this.initMerId(this.paramsList)
          break
        case 'prsell':
          this.currentPage = 1
          this.paramsList.pageNum = 1
          this.paramsList.previewStatus = 1
          this.initMerId(this.paramsList)
          window.scrollTo({
            top: PageId.offsetTop - 20,
            behavior: 'smooth'
          })
          break
        case 'cash':
          this.currentPage = 1
          this.paramsList.pageNum = 1
          this.paramsList.previewStatus = 0
          this.initMerId(this.paramsList)
          window.scrollTo({
            top: PageId.offsetTop - 20,
            behavior: 'smooth'
          })
          break
        case 'reseller':
          this.$router.push('/resell')
          break
        case 'Auction':
          this.$router.push('/auction')
          break
        case 'barter':
          this.$router.push('/barter')
          break
        default:
          break
      }
    },
    handleCurrentChange (val) { // 分页切换
      // console.log(`current page: ${val}`)
      this.currentPage = val
      this.paramsList.pageNum = this.currentPage
      this.initMerId(this.paramsList)
    },
    async initMerId (data) { // 初始化用户商品信息
      // console.log(this.$route.query.memberId)
      this.yushouLoading = true
      const res = await search(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.totalnum = res.data.data.total
        this.yushouList = res.data.data.list
        // console.log(this.yushouList)
        this.yushouList.forEach(item => {
          item.offerExpiryDate = moment(item.offerExpiryDate).format('YYYY-MM-DD')
        })
        this.yushouLoading = false
        // console.log(this.yushouList)
      }
    },
    async initTopList () { // 获取品种
      const res = await topicList()
      // console.log(res)
      if (res.data.code === 200) {
        this.titleOptions = res.data.data
      }
    },
    choseNational (item, index) { // 选择国家馆
      console.log(item)
      this.yushouList = []
      this.currentPage = 1
      this.chooseon = index
      this.chooseonName = item.countryName
      if (item.merchantId === 0) {
        return
      }
      this.paramsList.merchantId = item.merchantId
      this.initMerId(this.paramsList)
      this.initLevelOneData(item.id) // 初始化商品一级分类
      this.initUserId(item.countryCode) // 初始专区信息介绍
      // this.orderListdata(item.merchantId) // 初始化交易动态信息
      // this.initCharts(item.merchantId) // 初始化Echarts表格
    },
    toDetails (data) { // 到详情购买页面
      console.log(data)
      let routeData = null
      // this.$emit('dataToDetail', data)
      if (data.previewStatus === 0) { // 现货
        routeData = this.$router.resolve({
          path: '/cashcommodity/varietiy_details',
          query: {
            productSn: data.productSn
          }
        })
      } else if (data.previewStatus === 1) { // 预售
        routeData = this.$router.resolve({
          path: '/reservation/varietiy_details',
          query: {
            productSn: data.productSn
          }
        })
      } else if (data.previewStatus === 3) { // 易货
        routeData = this.$router.resolve({
          path: '/barter/detail',
          query: {
            id: data.id
          }
        })
      }
      window.open(routeData.href, '_blank')
    },
    ttchange (e) {
      // console.log(e)
      this.initCharts(e)
    },
    async selectAllChanqu () { // 查全部产区接口
      console.log('查所有产区')
      ElMessage.warning({
        message: '暂时没有更多产区数据！'
      })
    },
    async clickSelectname () { // 根据产品名称模糊搜索
      // console.log('模糊搜索产品')
      console.log(this.inputName)
      this.currentPage = 1 // 默认第一页
      this.paramsList.pageNum = 1 // 默认第一页
      this.paramsList.keyword = this.inputName
      this.initMerId(this.paramsList) // 开始查商品
    },
    handleAddMulti (name, content) { // 选中筛选项处理
      switch (name) {
        case 'zhuangyuans': // 庄园选中处理
          if (!this.isHaveList(content, this.manorNameList)) {
            this.manorNameList.push(content)
            this.tagsList.push({
              type: 'zhuangyuans',
              name: content
            })
          } else return ''
          break
        case 'pinjis': // 品级选中处理
          if (!this.isHaveList(content, this.gradeList)) {
            this.gradeList.push(content)
            this.tagsList.push({
              type: 'pinjis',
              name: content
            })
          } else return ''
          break
        case 'chanqus': // 产区选中处理
          if (!this.isHaveList(content, this.originPlaceList)) {
            this.originPlaceList.push(content)
            this.tagsList.push({
              type: 'chanqus',
              name: content
            })
          } else return ''
          break
        case 'chulis': // 处理法选中
          if (!this.isHaveList(content, this.processWayList)) {
            this.processWayList.push(content)
            this.tagsList.push({
              type: 'chulis',
              name: content
            })
          } else return ''
          break
        default:
          break
      }
    },
    handleClose (item, index) { // 删除选中的选项
      console.log('删除选中')
      console.log(item.name)
      switch (item.type) {
        case 'zhuangyuans': // 删除选中庄园处理
          this.manorNameList.splice(this.manorNameList.indexOf(item.name), 1)
          break
        case 'pinjis': // 删除选中品级处理
          this.gradeList.splice(this.gradeList.indexOf(item.name), 1)
          break
        case 'chanqus': // 删除选中产区处理
          this.originPlaceList.splice(this.originPlaceList.indexOf(item.name), 1)
          break
        case 'chulis': // 删除选中处理法处理
          this.processWayList.splice(this.processWayList.indexOf(item.name), 1)
          break
        default:
          break
      }
      this.tagsList.splice(index, 1)
    },
    isHaveList (newOne, oldList) { // 判断是否已经有选了该选项
      // console.log(newOne)
      // console.log(oldList)
      let flag = false
      for (const element of oldList) {
        if (element === newOne) {
          flag = true
          break
        } else {
          flag = false
        }
      }
      return flag
    },
    async initCharts (id) { // 初始化走势图
      const data = {
        varietyId: id || '',
        merchantId: this.paramsList.merchantId
      }
      const res = await queryTheStandardPriceTrendAccordingToTheVarietyAndMerchantId(data)
      // console.log(res)
      const da = res.data.data
      if (res.data.code === 200) {
        this.chartsSetOption.series[0].data = [
          da.januaryAveragePrice,
          da.februaryAveragePrice,
          da.marchAveragePrice,
          da.aprilAveragePrice,
          da.mayAveragePrice,
          da.juneAveragePrice,
          da.julyAveragePrice,
          da.augustAveragePrice,
          da.septemberAveragePrice,
          da.octoberAveragePrice,
          da.novemberAveragePrice,
          da.decemberAveragePrice
        ]
        // console.log(this.chartsSetOption)
        this.getOptions()
      }
    },
    async initUserId (code) { // 初始化根据国家编号查信息
      const data = {
        country: code,
        shopCode: code
      }
      const res = await cmsCsCountryIntro(data)
      console.log(res)
      if (res.data.code === 200) {
        // this.merchantMsg = res.data
        if (res.data.data.records.length1 !== 0) {
          this.merchantMsg = res.data.data.records[0]
        } else {
          this.merchantMsg = ''
        }
      }
    },
    // echarts表格实现方法
    getOptions () {
      const mychart = echarts.init(document.getElementById('show'))
      mychart.setOption(this.chartsSetOption)
    }
  },
  mounted () {
    this.initData()
    // this.initTopList() // 初始化 获取品种列表
  },
  setup (props, ctx) {
    return {
      tbtitle, Search
    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.content{
  background: #F2EFED;
  width: 100%;
  .nav_top{
    // display: block;
    background: linear-gradient(rgba(211, 237, 239, 0.631), rgba(19, 15, 10, 0.64)), url('../../static/imges/searchbg.png') no-repeat;
    background-size:cover;
    opacity: 0.9;
    margin: 0 auto;
    #logo{
      margin: 0 auto;
      width: 1200px;
      img{
        cursor: pointer;
        margin-right: 80px;
        z-index: 9999;
        width: 145px;
      }
    }
    .select_list{
      // padding: 20px;
      // width: 100%;
      margin: 0 auto;
      max-width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // background: yellowgreen;
      .list_li_cho{
        width: 150px;
        margin: 0 20px;
        margin-bottom: 20px;
        // background: #280E01;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .name{
          font-weight: bold;
          color: #F3ECDC;
          line-height: 20px;
          text-align: center;
          margin-top: 22px;
          margin-bottom: 32px;
        }
      }
      .chooseon{
        .name{
          color: #3C1C0B;
        }
      }
    }
  }
  .content_body{
    background: #ffffff;
    width: 1200px;
    margin: 0 auto;
    .select_con{
      margin-top: 10px;
      padding-top: 10px;
      width: 100%;
      .zhuanqu{
        margin: 40px 0;
        width: 1200px;
        text-align: center;
        font-size: 40px;
        color: #602e0b;
        font-weight: bolder;
      }
      /deep/ .main_select{
        .el-tabs{
          .el-tabs__header{
            .el-tabs__nav-wrap{
              .el-tabs__nav-scroll{
                display: flex;
                justify-content: center;
                .el-tabs__nav{
                  // margin: 0 auto;
                  height: 60px;
                  .el-tabs__active-bar{
                    background: #5A4944;
                  }
                  .el-tabs__item{
                    min-width: 150px;
                    text-align: center;
                    font-size: 22px;
                    font-weight: bold;
                    color: #D9D9D9;
                    padding: 0;
                  }
                  .is-active{
                    color: #5A4944;
                  }
                  .el-tabs__item:hover{
                    color: #6b5b57;
                  }
                }
              }
            }
            .el-tabs__nav-wrap::after{
              width: 0;
            }
          }
        }
      }
      .main_detail{
        width: 100%;
        .detail_title{
          height: 50px;
          line-height: 50px;
          text-align: center;
          // background: #AC703B;
          // color: #FFFFFF;
          font-weight: bold;
          color: #3C1C0B;
          background: #F3ECDC;
          font-size: 20px;
        }
        .detail_body{
          .xuan{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
          .shaixuan{
            padding: 0 20px;
            display: flex;
            min-height: 59px;
            justify-content: right;
            align-items: center;
            border-bottom: 1px solid #F2EFED;
            .selectul{
              display: none;
              padding: 0 50px;
              flex: 1;
              // display: none;
              width: 220px;
              // justify-content: space-between;
              .selectli{
                width: 100px;
                margin-right: 20px;
              }
            }
            .spanlist{
              flex: 1;
              padding: 0 50px;
              /deep/ .el-tag{
                margin:5px 0 ;
                margin-right: 10px;
                border-color: #C6824E;
                color: #C6824E;
                .el-icon{
                  color: #C6824E;
                }
                .el-icon:hover{
                  color: #ffffff;
                  --el-tag-hover-color: #8b553b;
                }
              }
            }
            .selectname{
              // height: 50px;
              display: flex;
              align-items: center;
            }
          }
          .title_div{
            padding: 0 20px;
            display: flex;
            // height: 49px;
            justify-content: right;
            align-items: center;
            border-bottom: 1px solid #F2EFED;
            .navspan{
              width: 100px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color:#333333;
            }
            .huotime{
              flex: 1;
              display: flex;
              flex-wrap: wrap;
              li{
                line-height: 50px;
                margin-right: 20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                cursor: pointer;
              }
            }
            .more{
              cursor: auto;
              color: #666666;
              text-align: end;
              .morespan{
                cursor: pointer;
              }
            }
          }
          .cont_body{
            .list{
              padding:20px 0;
              .list_li{
                display: flex;
                align-items: center;
                padding-left: 30px;
                position: relative;
                .minispan{
                  width: 30px;
                  height: 20px;
                  font-size: 12px;
                  color: #ffffff;
                  text-align: center;
                  line-height: 20px;
                  background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
                  border-radius: 0px 0px 4px 0px;
                  position: absolute;
                  top: 20px;
                }
                img{
                  width: 110px;
                  height: 110px;
                }
                .right_cc{
                  flex: 1;
                  display: flex;
                  height: 110px;
                  padding: 20px;
                  border-bottom: 1px solid #9999997b;
                  .right_cc_left{
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    p{
                      width: 100%;
                      font-size: 20px;
                    }
                    .name{
                      color: #666666;
                      font-size: 18px;
                      strong{
                        font-size: 20px;
                        font-weight: bold;
                        color: #333333;
                      }
                    }
                    .price_stock{
                      span{
                        font-weight: bold;
                        color: #333333;
                      }
                      .price{
                        display: inline-block;
                        width: 350px;
                        color: #ff1818;
                      }
                    }
                    .area_time{
                      color: #333333;
                      span{
                        font-weight: bold;
                        color: #333333;
                      }
                      .area{
                        display: inline-block;
                        width: 350px;
                      }
                    }
                  }
                  .right_cc_right{
                    // width: 180px;
                    display: flex;
                    align-items: center;
                    .btnspan{
                      cursor: pointer;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                    }
                  }
                }
              }
              .nodata{
                display: flex;
                justify-content: center;
              }
            }
          }
        }
        /deep/ .fenye{
          .showfooter{
            height: 100px;
            // background: red;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            .el-pagination{
              // line-height: 100px;
              .el-pager{
                margin: 0 20px;
                .number,.btn-quicknext{
                  margin: 0 5px;
                  width: 40px;
                  height: 40px;
                  border: 1px solid transparent;
                  line-height: 40px;
                }
                .active{
                  background: #ffffff;
                  color: #C6824E;
                  border-color: #C6824E;
                }
                .number:hover{
                  color: #C6824E;
                }
                .el-icon{
                  height: 40px;
                  line-height: 40px;
                }
              }
              .btn-prev,.btn-next,.el-pagination__jump{
                height: 40px;
                line-height: 40px;
                .el-input{
                  height: 40px;
                  .el-input__inner{
                    height: 40px;
                  }
                }
              }
            }
          }
        }
      }
      .main_msg{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px 100px 20px;
        box-sizing: border-box;
        .msg_left{
          width: 680px;
          #card_body{
            // height: 320px;
            // // overflow: hidden;
            font-size: 14px;
            font-family: Microsoft YaHei;
            #table_head{
              // padding: 0 10px;
              display: flex;
              // padding-top: 20px;
              tr{
                width: 100%;
                display: flex;
                height: 40px;
                justify-content: center;
                line-height: 40px;
                // text-align: center;
                th{
                  flex: 1;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #FFFFFF;
                  background: #AC703B;
                }
              }
            }
            .seamless-warp{
              height: 320px;
              overflow: hidden;
              // margin: 10px 0;
              #table_body{
                display: flex;
                align-items: center;
                // justify-content: center;
                table{
                  flex: 1;
                  display: flex;
                  flex-flow: column;
                  font-size: 12px;
                  padding: 0 10px;
                  tr{
                    height: 40px;
                    flex-shrink: 0;
                    border-bottom: 1px solid #cccccc;
                    display: flex;
                    td{
                      text-align: center;
                      flex: 1;
                      line-height:40px;
                      white-space:nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                  tr:hover{
                    // cursor: pointer;
                    background: rgba(255, 255, 255, 0.05);
                  }
                }
                .nodata{
                  flex: 1;
                  font-size: 20px;
                  height: 300px;
                  line-height: 300px;
                  font-weight: bold;
                  color: #C6824E;
                  // justify-content: center;
                  text-align: center;
                }
              }
            }
          }
        }
        .msg_right{
          flex: 1;
          padding: 0 20px;
          padding-left: 70px;
          display: flex;
          flex-flow: wrap;
          .right_title{
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin-left: 50px;
            .font_span{
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
            }
          }
          .right_echats{
            width: 100%;
            height: 320px;
            #show{
              width: 430px;
              height: 320px;
            }
          }
        }
        .cont_img{
          height: 100%;
          max-height: 180px;
          overflow: hidden;
        }
      }
    }
  }
  .divfooter{
    background: #280E01;
  }
}
</style>
